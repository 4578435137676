<template>
  <div class="pageBox">
    <div class="boxTop">
      <div class="topLeft" @click="goBack()"></div>
      <div class="topCon">收藏</div>
      <div class="topRight" @click="editEvent()" v-if="!flag">编辑</div>
      <div class="topRight1" v-else @click="flag = false">取消</div>
    </div>

    <van-loading id="loading" type="spinner" size="24px" v-if="loading === true" />
    <div class="containerBox" v-if="loading === false">
      <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
        <van-list v-model="upperLoading" :finished="finished" :immediate-check="false" finished-text="没有更多了" offset="500"
          @load="onLoad">
          <div class="container" v-for="(item, index) in messList" :key="index">
            <van-checkbox v-if="flag" v-model="item.checked" @click="checkEvent(item, index)"></van-checkbox>
            <van-swipe-cell>
              <div class="commentBox" @click="detailEvent(item)">
                <div class="commentTop">
                  <div class="commentContent" v-html="item.content"></div>
                  <div class="commenImg" v-if="item.infoImg != null"><img :src="item.infoImg" alt="" /></div>
                </div>
                <div class="commentFooter">收藏时间：{{ item.updateTime }}</div>
              </div>
            </van-swipe-cell>
          </div>
        </van-list>
      </van-pull-refresh>
      <div class="btnBox" v-if="flag">
        <div @click="delNull()">一键清空</div>
        <div @click="delEvent()">删除({{ this.delData.infoIds.length }})</div>
      </div>
    </div>
  </div>
</template>

<script>
import { infoStatePage, infoStateDel } from "../../../api/circle";
import { Toast } from "vant";
import { Dialog } from "vant";
export default {
  data() {
    return {
      loading: true,
      flag: false,
      messList: [],
      delData: {
        infoIds: [],
        operateType: 1,
      },
      upperLoading: false, //上拉加载
      finished: true, //加载与不加载的状态
      listTwo: [], //用于上拉加载时将数据合并
      isLoading: false, //下拉加载状态
      page: {
        pageNum: 1,
        pageSize: 10,
        operateType: 1
      }
    };
  },
  mounted() {
    this.loading = true;
    this.getinfoStatePage();
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    editEvent() {
      this.flag = true;
    },
    getinfoStatePage() {
      this.page.pageNum = 1
      infoStatePage(this.page).then((res) => {
        console.log(res, "点赞记录");
        // this.messList = this.messList.concat(res.data.data.items);
        // res.data.data.items.forEach((item) => {
        //   item.checked = false;
        // });
        // this.loading = false;
        // if (this.messList.length >= res.data.data.total) {
        //   this.finished = true;
        // }
        if (res.data.code === 0) {
          this.messList = res.data.data.items;
          res.data.data.items.forEach((item) => {
            item.checked = false;
          });
          this.total = res.data.data.total;
          this.loading = false;
          var num = this.total;
          this.total = num - 0;
          if (this.messList.length >= this.total) {
            //将返回数据长度与总条数进行比较控制加载不加载的状态
            this.finished = true;
          } else {
            this.finished = false;
          }
        }
      });
    },
    checkEvent(item, index) {
      console.log(item);
      this.delData.infoIds = [];
      this.messList[index].checked != item.checked;
      this.messList.forEach((i) => {
        if (i.checked) {
          this.delData.infoIds.push(i.id);
        }
      });
      console.log(this.delData.infoIds);
      this.$forceUpdate();
    },
    // 一键清空
    delNull() {
      let obj = {
        infoIds: [],
      };
      this.messList.forEach((i) => {
        console.log(i);
        obj.infoIds.push(i.id);
      });
      console.log(obj.infoIds);

      Dialog.confirm({
        title: "清空",
        message: "确定要清空吗？",
      })
        .then(() => {
          // on confirm
          infoStateDel(obj).then((res) => {
            console.log(res, "清空");
            if (res.data.code === 0) {
              Toast("删除成功");
              this.getinfoStatePage();
            }
          });
        })
        .catch(() => {
          // on cancel
        });
    },
    // 多个删除
    delEvent() {
      Dialog.confirm({
        title: "删除",
        message: "确定要删除当前信息吗？",
      })
        .then(() => {
          // on confirm
          infoStateDel(this.delData).then((res) => {
            console.log(res, "多个删除");
            if (res.data.code === 0) {
              Toast("删除成功");
              this.getinfoStatePage();
            }
          });
        })
        .catch(() => {
          // on cancel
        });
    },
    // 跳转详情
    detailEvent(item) {
      this.$router.push({ path: "/lookDetail", query: { infoId: item.infoId } });
      this.$store.commit("item", item);
    },
    // // 上拉刷新
    // onRefresh() {
    //   this.page.pageNum = 1;
    //   this.messList = [];
    //   this.getinfoStatePage();
    // },
    // // 下拉刷新
    // onLoad() {
    //   this.page.pageNum++;
    //   this.getinfoStatePage();
    // },
    onRefresh() {
      setTimeout(() => {
        this.$toast("刷新成功");
        this.isLoading = false;
        this.getinfoStatePage();
      }, 1000);
    },
    onLoad() {
      this.page.pageNum++; //加载时页码递增
      infoStatePage(this.page).then(res => {
        if (res.data.code === 0) {
          this.listTwo = res.data.data.items; //将接口返回赋值data定义的数组
          this.total = res.data.data.total - 0; //将接口返回的总数据条数赋值data中定义的total
          this.listTwo.forEach(item => {
            this.messList.push(item);
            this.upperLoading = false;
            // this.finished = false;
          });
          // 加载状态结束 状态更新为false
          this.upperLoading = false;
          if (this.messList.length >= this.total) {
            //将返回数据长度与总条数进行比较控制加载不加载的状态
            this.finished = true;
          }
        }
      });
    }
  },
};
</script>

<style scoped>
/deep/#loading {
  text-align: center;
  margin-top: 400px;
}

.boxTop {
  width: 750px;
  height: 88px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  position: fixed;
  top: 0px;
  background: #ffffff;
  z-index: 999;
}

.topLeft {
  width: 48px;
  height: 48px;
  background-image: url("../../../image/back.png");
  background-size: 100% 100%;
  margin-left: 32px;
}

.topCon {
  width: 320px;
  font-size: 32px;
  font-weight: 600;
  color: #0b1526;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.topRight,
.topRight1 {
  width: 308px;
  font-size: 28px;
  font-weight: 600;
  color: #0b1526;
  text-align: right;
}

.topRight1 {
  color: #5777ed;
}

.containerBox {
  margin-top: 120px;
  margin-bottom: 20px;
}

.container {
  margin-left: 32px;
  display: flex;
}

.contentTop {
  display: flex;
  align-items: center;
}

.contentHear {
  width: 80px;
  height: 80px;
  background: red;
  border-radius: 50%;
}

.commentBox {
  width: 686px;
  border-radius: 16px 16px 16px 16px;
  opacity: 1;
  overflow: hidden;
  margin-bottom: 48px;
}

.commentTop {
  display: flex;
}

.commentContent {
  min-width: 450px;
  height: 110px;
  font-size: 28px;
  font-weight: 400;
  color: #0b1526;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.commenImg {
  width: 204px;
  height: 136px;
  margin-left: 32px;
}

.commenImg img {
  width: 204px;
  height: 136px;
  border-radius: 8px;
}

.commentFooter {
  height: 36px;
  font-size: 24px;
  font-weight: 400;
  color: #97a4b4;
  margin-top: 16px;
}

/deep/.van-checkbox {
  width: 50px;
  height: 50px;
  align-items: baseline;
  margin-right: 32px;
}

.btnBox {
  width: 750px;
  height: 150px;
  display: flex;
  position: fixed;
  bottom: 0px;
  background: #fff;
}

.btnBox div:nth-child(1) {
  width: 274px;
  height: 90px;
  border-radius: 16px 16px 16px 16px;
  border: 2px solid #fa2819;
  font-size: 28px;
  font-weight: 600;
  color: #fa2819;
  text-align: center;
  line-height: 90px;
  margin-left: 32px;
}

.btnBox div:nth-child(2) {
  width: 380px;
  height: 90px;
  background: #fa2819;
  border-radius: 16px 16px 16px 16px;
  opacity: 1;
  font-size: 28px;
  font-weight: 600;
  color: #ffffff;
  text-align: center;
  line-height: 90px;
  margin-left: 32px;
}
</style>
